<template>
  <el-card class="box-card">
    <el-steps :active="stepsActive" align-center style="margin-bottom: 30px">
      <!--循环输出-->
      <el-step
        v-for="(item, index) in steps"
        :key="index"
        :title="item"
      ></el-step>
    </el-steps>
    <avue-form
      ref="addForm"
      :option="formOp"
      @submit="handleSubmit"
      v-model="formDa"
    >
      <template slot-scope="{ disabled, size }" slot="skuStockList">
        <div>
          <el-card shadow="never" class="cardBg">
            <div v-for="(productAttr, idx) in productAttrValue" :key="idx">
              {{ productAttr.name }}：
              <el-checkbox-group
                v-if="productAttr.handAddStatus == 0"
                v-model="productAttrValue[idx].values"
              >
                <el-checkbox
                  v-for="item in productAttr.inputList"
                  :label="item"
                  :key="item"
                  class="littleMarginLeft"
                ></el-checkbox>
              </el-checkbox-group>
              <div v-else>
                <el-checkbox-group v-model="productAttrValue[idx].values">
                  <div
                    v-for="(item, index) in productAttrValue[idx].options"
                    :key="index"
                    style="display: inline-block"
                    class="littleMarginLeft"
                  >
                    <el-checkbox :label="item" :key="item"></el-checkbox>
                    <el-button
                      type="text"
                      class="littleMarginLeft"
                      @click="handleRemoveProductAttrValue(idx, index)"
                      >删除
                    </el-button>
                  </div>
                </el-checkbox-group>
                <el-input
                  v-model="addProductAttrValue"
                  style="width: 160px; margin-left: 10px"
                  :disabled="disabled"
                  :size="size"
                  clearable
                ></el-input>
                <el-button
                  class="littleMarginLeft"
                  @click="handleAddProductAttrValue(idx)"
                  >增加</el-button
                >
              </div>
            </div>
          </el-card>
          <el-table
            style="width: 100%; margin-top: 20px"
            :data="formDa.skuStockList"
            border
          >
            <el-table-column label="SKU编号" width="80" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.skuCode"></el-input>
              </template>
            </el-table-column>
            <!--     获取来的列 productAttrValue   -->
            <el-table-column
              v-for="(item, index) in productAttrValue"
              :label="item.name"
              :key="item.id"
              align="center"
              min-width="100"
              max-width="200"
            >
              <template slot-scope="scope">
                {{ getProductSkuSp(scope.row, index) }}
              </template>
            </el-table-column>
            <!--     固定列       -->
            <el-table-column label="销售价格" width="150" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.price"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="库存" width="150" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.stock"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="库存预警值" width="150" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.lowStock"></el-input>
              </template>
            </el-table-column>

            <el-table-column
              v-if="formDa.is_course > 0"
              label="参与次数"
              width="160"
              align="center"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.class"
                  :min="1"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleRemoveProductSku(scope.$index, scope.row)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button
            type="primary"
            style="margin-top: 20px"
            @click="handleRefreshProductSkuList"
            >刷新列表
          </el-button>
          <!-- <el-button
            type="primary"
            style="margin-top: 20px"
            @click="handleSyncProductSkuPrice"
            >同步价格
          </el-button>
          <el-button
            type="primary"
            style="margin-top: 20px"
            @click="handleSyncProductSkuStock"
            >同步库存
          </el-button> -->
        </div>
      </template>
      <template slot="menuForm">
        <el-button @click="preClick" v-show="btnList.preBtn">{{
          btnList.preBtnText
        }}</el-button>
        <el-button type="primary" v-show="btnList.nextBtn" @click="nextClick">{{
          btnList.nextBtnText
        }}</el-button>
      </template>
    </avue-form>
  </el-card>
</template>
<script>
import add from './add';

export default add;
</script>
