/* eslint-disable no-continue */
import './list.scss';
import dataApi from '@/api/product/product.js';
// eslint-disable-next-line no-unused-vars
const EDITORCONF = [
  'head',
  'italic',
  'underline',
  'strikeThrough',
  'lineHeight',
  'backColor',
  'link',
  'todo',
  'justify',
  'quote',
  'image',
  'video',
  'table',
  'code',
  'splitLine',
  'undo',
  'redo'
];
export default {
  name: 'product_add',
  components: {},
  data() {
    return {
      productId: 0,
      stepsActive: 0,
      isEdit: false,
      // 编辑模式时是否初始化成功
      hasEditCreated: false,
      steps: ['填写基本信息', '填写活动属性', '填写运营信息'],
      btnList: {
        preBtn: false,
        nextBtn: true,
        preBtnText: '上一步',
        nextBtnText: '下一步'
      },
      columns: [
        [
          {
            type: 'input',
            label: '名称',
            span: 24,
            display: true,
            prop: 'name',
            required: true,
            rules: [
              {
                required: true,
                message: '名称必须填写'
              }
            ]
          },
          {
            type: 'tree',
            label: '所属分类',
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_product_category`,
            dicQuery: {},
            dicMethod: 'post',
            span: 12,
            display: true,
            prop: 'product_category_id',
            required: true,
            rules: [
              {
                required: true,
                message: '所属分类必须填写'
              }
            ]
          },
          // {
          //   type: 'select',
          //   label: '所属品牌',
          //   span: 12,
          //   display: true,
          //   props: {
          //     label: 'label',
          //     value: 'value'
          //   },
          //   prop: 'product_brand_id',
          //   dicUrl: `${this.$baseUrl}general/get_product_brand`,
          //   dicQuery: {},
          //   dicMethod: 'post',
          //   required: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: '所属品牌必须填写'
          //     }
          //   ]
          // },
          {
            type: 'input',
            label: '能量值',
            span: 12,
            display: true,
            prop: 'price',
            required: true,
            rules: [
              {
                required: true,
                message: '售价必须填写'
              }
            ],
            value: 0
          },
          // {
          //   type: 'input',
          //   label: '市场价',
          //   span: 12,
          //   display: true,
          //   prop: 'original_price',
          //   required: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: '市场价必须填写'
          //     }
          //   ],
          //   value: 0
          // },
          {
            type: 'number',
            label: '库存',
            span: 12,
            display: true,
            prop: 'stock',
            required: true,
            rules: [
              {
                required: true,
                message: '库存必须填写'
              }
            ],
            value: 0
          },
          {
            type: 'number',
            label: '库存预警值',
            controls: true,
            span: 12,
            display: true,
            prop: 'low_stock',
            required: true,
            rules: [
              {
                required: true,
                message: '库存必须填写'
              }
            ],
            value: 0
          },
          {
            type: 'number',
            label: '排序',
            controls: true,
            span: 12,
            display: true,
            prop: 'sorting',
            required: true,
            rules: [
              {
                required: true,
                message: '排序必须填写'
              }
            ],
            value: 0
          },
          // {
          //   type: 'input',
          //   label: '计量单位',
          //   span: 12,
          //   display: true,
          //   prop: 'unit',
          //   required: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: '计量单位必须填写'
          //     }
          //   ]
          // },
          {
            label: '活动缩略图',
            prop: 'pic',
            type: 'upload',
            span: 12,
            listType: 'picture-img',
            display: true,
            required: true,
            accept: 'image/png, image/jpeg',
            rules: [
              {
                required: true,
                message: '请上传图片'
              }
            ],
            tip: '只能上传jpg/png图片,且不超过500kb',
            action: '/system/upload'
          }
          // {
          //   type: 'textarea',
          //   label: '活动描述',
          //   span: 12,
          //   display: true,
          //   prop: 'description'
          // },
          // {
          //   type: 'input',
          //   label: '活动重量',
          //   span: 12,
          //   display: true,
          //   prop: 'weight',
          //   required: false,
          //   append: '克',
          //   value: 0
          // },
          // {
          //   type: 'number',
          //   label: '活动销量',
          //   span: 12,
          //   display: true,
          //   prop: 'sale',
          //   required: false,
          //   value: 0
          // }
        ],
        [
          {
            type: 'radio',
            label: '活动类型',
            dicData: [
              // {
              //   label: '培训师',
              //   value: 4
              // },
              {
                label: '开班排课',
                value: 3
              },
              // {
              //   label: '品类通兑',
              //   value: 2
              // },
              {
                label: '通兑课程',
                value: 1
              }
              // {
              //   label: '普通活动',
              //   value: 0
              // }
            ],
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'is_course',
            required: true,
            value: 0,
            rules: [
              {
                required: true,
                message: '活动类型必须填写'
              }
            ]
          },
          {
            type: 'select',
            label: '活动类型',
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_product_class`,
            dicQuery: {},
            dicMethod: 'post',
            span: 24,
            display: true,
            prop: 'product_attr_id',
            required: true,
            rules: [
              {
                required: true,
                message: '活动类型必须填写'
              }
            ],
            change: this.handleProductAttrChange,
            value: ''
          },
          {
            label: '活动规格',
            prop: 'skuStockList',
            formslot: true,
            span: 24,
            display: true
          },
          {
            label: '活动相册',
            prop: 'album_pics',
            type: 'upload',
            span: 24,
            listType: 'picture-card',
            display: true,
            tip: '只能上传jpg/png图片,且不超过500kb',
            action: `${this.$baseUrl}system/upload`,
            required: true,
            accept: 'image/png, image/jpeg',
            rules: [
              {
                required: true,
                message: '请上传图片'
              }
            ]
          },
          {
            type: 'ueditor',
            component: 'avue-ueditor',
            label: '活动介绍',
            span: 24,
            display: true,
            action: `${this.$baseUrl}system/upload`,
            prop: 'detail_desc',
            required: true,
            rules: [
              {
                required: true,
                message: '请填写活动介绍'
              }
            ]
          }
        ],
        [
          {
            type: 'number',
            label: '赠送积分',
            span: 12,
            display: true,
            prop: 'gift_point',
            value: 0
          },
          {
            type: 'number',
            label: '赠送能量值',
            span: 12,
            display: true,
            prop: 'gift_energy',
            value: 0
          },
          {
            type: 'number',
            label: '积分购买限制',
            span: 12,
            display: true,
            prop: 'use_point_limit',
            value: 0
          },
          {
            type: 'number',
            label: '签到消耗能量值',
            span: 12,
            display: true,
            prop: 'use_energy',
            value: 0
          },

          {
            type: 'select',
            label: '活动标签',
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            multiple: true,
            dicUrl: `${this.$baseUrl}general/get_product_tag`,
            dicQuery: {
              type: 1
            },
            dicMethod: 'post',
            prop: 'service_ids'
          },
          {
            type: 'select',
            label: '活动亮点',
            span: 24,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            multiple: true,
            dicUrl: `${this.$baseUrl}general/get_product_tag`,
            dicQuery: {
              type: 2
            },
            dicMethod: 'post',
            prop: 'bright_ids'
          },
          {
            label: '推荐位',
            type: 'select',
            span: 12,
            display: true,
            dicFlag: true,
            prop: 'recommend',
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicQuery: {
              dict_type: 'recommend'
            },
            dicMethod: 'post',
            dataType: 'string'
          },
          {
            type: 'radio',
            label: '状态',
            dicData: [
              {
                label: '启用',
                value: 1
              },
              {
                label: '禁用',
                value: -1
              }
            ],
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status',
            value: 1,
            required: true,
            rules: [
              {
                required: true,
                message: '状态必须填写'
              }
            ]
          },
          {
            type: 'radio',
            label: '兑换场景',
            dicData: [
              {
                label: '全部',
                value: 1
              },
              {
                label: '仅前台',
                value: 2
              }
            ],
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'is_exchange',
            value: 1,
            required: true,
            rules: [
              {
                required: true,
                message: '兑换场景必须填写'
              }
            ]
          }
        ]
      ],
      formOp: {
        column: [],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 150,
        gutter: 0,
        disabled: false,
        menuBtn: true,
        submitBtn: false,
        submitText: '完成,提交活动',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center'
      },
      formDa: {},
      productAttrValue: [],
      productParamValue: [],
      // 选中的活动属性图片
      selectProductAttrPics: [],
      // 可手动添加的活动属性
      addProductAttrValue: ''
    };
  },
  created() {
    const { query } = this.$route;

    if (query.id) {
      this.productId = query.id;
      this.getInfo();
    }
    this.formDaInit();
  },
  computed: {
    // 是否有活动属性图片
    hasAttrPic() {
      if (this.selectProductAttrPics.length < 1) {
        return false;
      }
      return true;
    },
    // 活动的编号
    // productId() {
    //   return this.formDa.id;
    // },
    // 活动的主图和画册图片
    selectProductPics: {
      get() {
        const pics = [];
        if (
          this.formDa.pic === undefined ||
          this.formDa.pic == null ||
          this.formDa.pic === ''
        ) {
          return pics;
        }
        pics.push(this.formDa.pic);
        if (
          this.formDa.albumPics === undefined ||
          this.formDa.albumPics == null ||
          this.formDa.albumPics === ''
        ) {
          return pics;
        }
        const albumPics = this.formDa.albumPics.split(',');
        for (let i = 0; i < albumPics.length; i++) {
          pics.push(albumPics[i]);
        }
        return pics;
      },
      set(newValue) {
        if (newValue == null || newValue.length === 0) {
          this.formDa.pic = null;
          this.formDa.albumPics = null;
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.formDa.pic = newValue[0];
          this.formDa.albumPics = '';
          if (newValue.length > 1) {
            for (let i = 1; i < newValue.length; i++) {
              this.formDa.albumPics += newValue[i];
              if (i !== newValue.length - 1) {
                this.formDa.albumPics += ',';
              }
            }
          }
        }
      }
    }
  },
  watch: {
    // productId(newValue) {
    //   if (!this.isEdit) return;
    //   if (this.hasEditCreated) return;
    //   if (newValue === undefined || newValue == null || newValue === 0) return;
    //   this.handleEditCreated();
    // }
  },
  mounted() {},
  filters: {},
  methods: {
    // 获取活动详情
    getProductDetails() {},
    preClick() {
      console.log('preClick', this.stepsActive);
      this.stepsActive -= 1;
      this.formOp.column = this.columns[this.stepsActive - 1];
      if (this.stepsActive - 1 === 0) {
        this.btnList.preBtn = false;
      } else {
        this.btnList = {
          preBtn: true,
          nextBtn: true,
          preBtnText: `上一步,${this.steps[this.stepsActive - 1]}`,
          nextBtnText: `下一步,${this.steps[this.stepsActive]}`
        };
      }
      this.formOp.disabled = false;
      this.formOp.submitBtn = false;
    },
    nextClick() {
      // 校验表单
      this.$refs.addForm.validate((valid, done) => {
        done();
        if (valid) {
          this.formOp.column = this.columns[this.stepsActive];
          this.updateUrlDic();
          if (this.stepsActive === this.columns.length - 1) {
            this.btnList.nextBtn = false;
            this.formOp.submitBtn = true;
            this.btnList.nextBtnText = '完成,提交活动';
          } else {
            this.btnList = {
              preBtn: true,
              nextBtn: true,
              preBtnText: `上一步,${this.steps[this.stepsActive - 1]}`,
              nextBtnText: `下一步,${this.steps[this.stepsActive]}`
            };
          }
          this.stepsActive += 1;
        } else {
          this.$message.error('请检查表单');
          return false;
        }
      });
    },
    updateUrlDic() {
      this.$refs.addForm.updateDic();
    },
    // 添加
    handleSubmit(form, done) {
      if (this.$route.query.id) {
        // form.specs.sku_list = form.specs.sku_list.sp_data
        form.specs.sku_list.forEach((item, index) => {
          form.specs.sku_list[index].sp_data = JSON.stringify(item.sp_data);
        });
        form.skuStockList = form.specs.sku_list;
        delete form.specs;
        dataApi.update.r(form).then(() => {
          this.$message.success('修改成功~');
          done(form);
          // this.getList();
          this.$router.push(`/manage/product`);
        });
      } else {
        dataApi.add.r(form).then(() => {
          this.$message.success('添加成功~');
          done(form);
          // this.getList();
          this.$router.push(`/manage/product`);
        });
      }
      console.log(form);
      done();
    },
    getInfo() {
      dataApi.getOne.r({ id: this.productId }).then((res) => {
        console.log('接口返回', res);
        this.formDa = res.data;
        this.isEdit = true;
      });
    },
    // 表单初始化
    formDaInit() {
      this.formOp.column = this.columns[this.stepsActive];
      this.stepsActive += 1;
      this.btnList = {
        preBtn: false,
        nextBtn: true,
        nextBtnText: `下一步,${this.steps[this.stepsActive]}`
      };
      this.formDa = {
        id: '',
        new_status: 1,
        use_energy: 0,
        use_point_limit: 0,
        gift_energy: 0,
        gift_point: 0,
        filter_type: 0,
        product_attr_id: 0,
        search_type: 0,
        related_status: 0,
        input_type: 0,
        select_type: 0,
        hand_add_status: 0,
        skuStockList: [],
        status: 1,
        sorting: 0,
        type: 0
      };
    },
    // 获取活动属性
    getProductAttrValue(type, attrId) {
      dataApi.getProductAttrValue
        .r({ type: type, attr_id: attrId })
        .then((res) => {
          console.log('接口返回', res);
          const list = res.data;
          if (type === 0) {
            this.productAttrValue = [];
            for (let i = 0; i < list.length; i++) {
              const options = [];
              let values = [];
              if (this.isEdit) {
                if (list[i].hand_add_status === 1) {
                  // 编辑状态下获取手动添加编辑属性
                  // options = this.getEditAttrOptions(list[i].id);
                }
                // 编辑状态下获取选中属性
                values = this.getEditAttrValues(i);
              }
              this.productAttrValue.push({
                id: list[i].id,
                name: list[i].name,
                handAddStatus: list[i].hand_add_status,
                inputList: list[i].input_list,
                values: values,
                options: options
              });
            }
            // if (this.isEdit) {
            //   // 编辑模式下刷新活动属性图片
            //   this.refreshProductAttrPics();
            // }
            console.log('活动属性', this.productAttrValue);
          } else {
            this.productParamValue = [];
            for (let i = 0; i < list.length; i++) {
              let value = null;
              if (this.isEdit) {
                // 编辑模式下获取参数属性
                value = this.getEditParamValue(list[i].id);
              }
              this.productParamValue.push({
                id: list[i].id,
                name: list[i].name,
                value: value,
                inputType: list[i].inputType,
                inputList: list[i].inputList
              });
            }
          }
          // 编辑模式下 商品属性赋初始值
          if (this.$route.query.id) {
            if (this.formDa.specs.spec_list.length > 1) {
              this.formDa.specs.spec_list[1].list.forEach((item) => {
                this.addProductAttrValue = item.name;
                this.handleAddProductAttrValue(1);
                this.productAttrValue[1].values.push(item.name);
              });
            }

            this.formDa.specs.spec_list[0].list.forEach((item) => {
              this.addProductAttrValue = item.name;
              this.handleAddProductAttrValue(0);
              this.productAttrValue[0].values.push(item.name);
            });
            this.formDa.skuStockList = this.formDa.specs.sku_list;
          }
        });
    },
    // 获取设置的可手动添加属性值
    getEditAttrOptions(id) {
      const options = [];
      console.log(this.formDa);
      for (let i = 0; i < this.formDa.productAttributeValueList.length; i++) {
        const attrValue = this.formDa.productAttributeValueList[i];
        if (attrValue.productAttributeId === id) {
          const strArr = attrValue.value.split(',');
          for (let j = 0; j < strArr.length; j++) {
            options.push(strArr[j]);
          }
          break;
        }
      }
      return options;
    },
    // 获取选中的属性值
    getEditAttrValues(index) {
      const values = new Set();
      if (index === 0) {
        for (let i = 0; i < this.formDa.skuStockList.length; i++) {
          const sku = this.formDa.skuStockList[i];
          let sp_data;
          if (typeof sku.sp_data === 'string') {
            sp_data = JSON.parse(sku.sp_data);
          } else {
            sp_data = sku.sp_data;
          }
          if (sp_data != null && sp_data.length >= 1) {
            values.add(sp_data[0].value);
          }
        }
      } else if (index === 1) {
        for (let i = 0; i < this.formDa.skuStockList.length; i++) {
          const sku = this.formDa.skuStockList[i];
          let sp_data;
          if (typeof sku.sp_data === 'string') {
            sp_data = JSON.parse(sku.sp_data);
          } else {
            sp_data = sku.sp_data;
          }
          if (sp_data != null && sp_data.length >= 2) {
            values.add(sp_data[1].value);
          }
        }
      } else {
        for (let i = 0; i < this.formDa.skuStockList.length; i++) {
          const sku = this.formDa.skuStockList[i];
          let sp_data;
          if (typeof sku.sp_data === 'string') {
            sp_data = JSON.parse(sku.sp_data);
          } else {
            sp_data = sku.sp_data;
          }
          if (sp_data != null && sp_data.length >= 3) {
            values.add(sp_data[2].value);
          }
        }
      }
      return Array.from(values);
    },
    // 获取属性的值
    getEditParamValue(id) {
      for (let i = 0; i < this.formDa.productAttributeValueList.length; i++) {
        if (
          id === this.formDa.productAttributeValueList[i].productAttributeId
        ) {
          return this.formDa.productAttributeValueList[i].value;
        }
      }
    },
    handleProductAttrChange(val) {
      // 活动属性改变时，获取属性值
      const { value, column } = val;
      console.log('handleProductAttrChange', value, column);
      this.getProductAttrValue(0, value);
      // this.getProductAttrValue(1, value);
    },
    getInputListArr(inputList) {
      return inputList.split(',');
    },
    handleRemoveProductSku(index, row) {
      console.log('handleRemoveProductSku', index, row);
      const list = this.formDa.skuStockList;
      if (list.length === 1) {
        list.pop();
      } else {
        list.splice(index, 1);
      }
    },
    getProductSkuSp(row, index) {
      let { sp_data } = row;
      if (typeof sp_data === 'string') {
        sp_data = JSON.parse(sp_data);
      }
      if (sp_data != null && index < sp_data.length) {
        return sp_data[index].value;
      }
      return null;
    },
    handleRefreshProductSkuList() {
      this.$confirm('刷新列表将导致sku信息重新生成，是否要刷新', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.refreshProductAttrPics();
        this.refreshProductSkuList();
      });
    },
    handleSyncProductSkuPrice() {
      this.$confirm('将同步第一个sku的价格到所有sku,是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (
          this.formDa.skuStockList !== null &&
          this.formDa.skuStockList.length > 0
        ) {
          let tempSkuList = [];
          tempSkuList = tempSkuList.concat(
            tempSkuList,
            this.formDa.skuStockList
          );
          console.log('tempSkuList', tempSkuList);
          const { price } = this.formDa.skuStockList[0];
          for (let i = 0; i < tempSkuList.length; i++) {
            tempSkuList[i].price = price;
          }
          this.formDa.skuStockList = [];
          this.formDa.skuStockList = this.formDa.skuStockList.concat(
            this.formDa.skuStockList,
            tempSkuList
          );
        }
      });
    },
    handleSyncProductSkuStock() {
      this.$confirm('将同步第一个sku的库存到所有sku,是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (
          this.formDa.skuStockList !== null &&
          this.formDa.skuStockList.length > 0
        ) {
          let tempSkuList = [];
          tempSkuList = tempSkuList.concat(
            tempSkuList,
            this.formDa.skuStockList
          );
          const { stock } = this.formDa.skuStockList[0];
          const { lowStock } = this.formDa.skuStockList[0];
          for (let i = 0; i < tempSkuList.length; i++) {
            tempSkuList[i].stock = stock;
            tempSkuList[i].lowStock = lowStock;
          }
          this.formDa.skuStockList = [];
          this.formDa.skuStockList = this.formDa.skuStockList.concat(
            this.formDa.skuStockList,
            tempSkuList
          );
        }
      });
    },
    refreshProductSkuList() {
      this.formDa.skuStockList = [];
      const skuList = this.formDa.skuStockList;
      // 只有一个属性时
      if (this.productAttrValue.length === 1) {
        const attr = this.productAttrValue[0];
        for (let i = 0; i < attr.values.length; i++) {
          skuList.push({
            sp_data: JSON.stringify([{ key: attr.name, value: attr.values[i] }])
          });
        }
      } else if (this.productAttrValue.length === 2) {
        const attr0 = this.productAttrValue[0];
        const attr1 = this.productAttrValue[1];
        for (let i = 0; i < attr0.values.length; i++) {
          if (attr1.values.length === 0) {
            skuList.push({
              sp_data: JSON.stringify([
                { key: attr0.name, value: attr0.values[i] }
              ])
            });
            continue;
          }
          for (let j = 0; j < attr1.values.length; j++) {
            const sp_data = [];
            sp_data.push({ key: attr0.name, value: attr0.values[i] });
            sp_data.push({ key: attr1.name, value: attr1.values[j] });
            skuList.push({
              sp_data: JSON.stringify(sp_data)
            });
          }
        }
        console.log(this.formDa);
      } else {
        const attr0 = this.productAttrValue[0];
        const attr1 = this.productAttrValue[1];
        const attr2 = this.productAttrValue[2];
        for (let i = 0; i < attr0.values.length; i++) {
          if (attr1.values.length === 0) {
            skuList.push({
              sp_data: JSON.stringify([
                { key: attr0.name, value: attr0.values[i] }
              ])
            });
            continue;
          }
          for (let j = 0; j < attr1.values.length; j++) {
            if (attr2.values.length === 0) {
              const sp_data = [];
              sp_data.push({ key: attr0.name, value: attr0.values[i] });
              sp_data.push({ key: attr1.name, value: attr1.values[j] });
              skuList.push({
                sp_data: JSON.stringify(sp_data)
              });
              continue;
            }
            for (let k = 0; k < attr2.values.length; k++) {
              const sp_data = [];
              sp_data.push({ key: attr0.name, value: attr0.values[i] });
              sp_data.push({ key: attr1.name, value: attr1.values[j] });
              sp_data.push({ key: attr2.name, value: attr2.values[k] });
              skuList.push({
                sp_data: JSON.stringify(sp_data)
              });
            }
          }
        }
      }
    },
    /*     refreshProductAttrPics() {
      this.productAttrValuePics = [];
      if (this.productAttrValue.length >= 1) {
        const { values } = this.productAttrValue[0];
        console.log(values);
        console.log('============================================');
        for (let i = 0; i < values.length; i++) {
          let pic = null;
          if (this.isEdit) {
            // 编辑状态下获取图片
            // pic = this.getProductSkuPic(values[i]);
            pic = '';
          }
          this.productAttrValuePics.push({ name: values[i], pic: pic });
        }
      }
    }, */
    handleRemoveProductAttrValue(idx, index) {
      this.productAttrValue[idx].options.splice(index, 1);
    },
    handleAddProductAttrValue(idx) {
      const { options } = this.productAttrValue[idx];
      if (this.addProductAttrValue == null || this.addProductAttrValue === '') {
        this.$message({
          message: '属性值不能为空',
          type: 'warning',
          duration: 1000
        });
        return;
      }
      if (options.indexOf(this.addProductAttrValue) !== -1) {
        this.$message({
          message: '属性值不能重复',
          type: 'warning',
          duration: 1000
        });
        return;
      }
      this.productAttrValue[idx].options.push(this.addProductAttrValue);
      this.addProductAttrValue = null;
    }
  }
};
